import axios from "axios";

const baseURL =  process.env.REACT_APP_API_URL?? "https://www.carvinu.com/api";


export const axiosCarvenui = axios.create({
  baseURL,
});



export const authApi = axios.create({
	baseURL,
	headers: {
		'Content-Type': 'application/json',
		'Authorization': `Bearer ${localStorage.getItem('access_token')}`
	},
})


// Response interceptor
authApi.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		return Promise.reject(error);
	}
);



