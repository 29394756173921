import React, { useContext } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useForm } from "react-hook-form";

import ar from "../../util/locales/ar";
import en from "../../util/locales/en";

import { StyledContainer } from "./Account";
import Button from "../../Components/MyAccount/Button";
import AccTitle from "../../Components/MyAccount/AccTitle";
import InputField from "../../Components/MyAccount/InputField";
import { ReactComponent as UserIcon } from "../../images/Account/user.svg";
import { ButtonGroup, FormContainer, InfoCard } from "../../Components/MyAccount/CommonStyled";
import AuthContext from "../../hooks/Auth/providers/AuthContext";
import { useUpdateAccount } from "../../hooks/AccountHooks";

function AccountInfo() {
  const language = useSelector(state => state.language);
  const t = language === "en" ? en : ar;
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);
  const { register, formState, handleSubmit } = useForm({
    defaultValues: { ...user },
  });

  const { updateAccountInfo, isLoading } = useUpdateAccount(user?.id);

  const { errors, isDirty } = formState;
  const emailError = errors?.email?.message;
  const nameError = errors?.name?.message;

  const onSubmit = data => {
    if (data.name_ar === null || data.name_ar === "") {
      delete data.name_ar;
    }
    console.log("data: ", data)
    updateAccountInfo(data);
  };

  return (
    <StyledContainer dir={language === "ar" ? "rtl" : "ltr"} language={language}>
      <AccTitle title={t.account.accInfo} description={t.account.accInfoDesc} />
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <div className="container">
          <div>
            <InputGroup>
              <NameCard>
                <span>{t.account.fullName}</span>
                <p>{user?.name}</p>
              </NameCard>

              <InputField
                id="birthDate"
                label={t.account.birthDate}
                placeholder={t.account.enterBirthDate}
                type="date"
                {...register("extra_details.birth_date")}
              />
              {/* name */}
              <InputField
                id="name"
                type="text"
                errMessage={nameError}
                label={t.account.fullName}
                placeholder={t.account.enterFullName}
                {...register("name", {
                  required: t.thisFieldIsRequired,
                })}
              />
              {/* name_ar */}
              <InputField
                id="name_ar"
                type="text"
                label={t.account.fullNameAr}
                placeholder={t.account.enterFullName}
                {...register("name_ar")}
              />
              <InputField
                errMessage={emailError}
                id="email"
                label={t.account.email}
                placeholder={t.account.enterEmail}
                type="text"
                {...register("email", {
                  required: t.thisFieldIsRequired,
                })}
              />
              <InputField
                disabled={true}
                id="phonenumber"
                label={t.account.mobileNumber}
                placeholder={t.account.mobileNumber}
                type="text"
                {...register("phonenumber", {
                  required: t.thisFieldIsRequired,
                })}
              />
              <InputField
                id="address"
                label={t.account.address}
                placeholder={t.account.enterAddress}
                type="text"
                {...register("extra_details.address")}
              />
              <InputField
                id="nationalId"
                label={t.account.nationalId}
                placeholder={t.account.enterNationalId}
                type="text"
                {...register("extra_details.nationalId")}
              />
            </InputGroup>
          </div>
          <div>
            <InfoCard>
              <span>
                <UserIcon />
              </span>
              <h4>{t.account.generalInfo}</h4>
              <p>{t.account.generalInfoDesc}</p>
            </InfoCard>
          </div>
        </div>
        <ButtonGroup>
          <Button type="button" variation="secondary">
            {t.account.deactivateAccount}
          </Button>
          <div>
            <Button isLoading={isLoading} disabled={!isDirty}>
              {t.account.updateAccount}
            </Button>
            <Button onClick={() => navigate(-1)} type="button" variation="secondary">
              {t.cancel}
            </Button>
          </div>
        </ButtonGroup>
      </FormContainer>
    </StyledContainer>
  );
}

export default AccountInfo;

const NameCard = styled.div`
  padding: 1rem;
  background-color: white;
  border-radius: 10px;
  height: fit-content;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  span {
    font-size: 0.8rem;
    color: #333;
  }
  p {
    padding-top: 0.4rem;
    font-weight: bold;
    font-size: 1rem;
    color: #333;
  }
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 1rem;
`;
