import en from "./en";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  showMore: "عرض المزيد",
  showLess: "عرض الأقل",
  required: "يجب إدخال",
  invalid: " غير صحيح",
  passwords_dont_match: "كلمة المرور غير متطابقة",
  next: "التالي",
  calculate: "احسب",
  taxExcluded: "الضريبة مستثناة",
  totalPrice: "السعر الإجمالي",
  mobileNumber: "رقم الهاتف",
  recommended: "الموصى بها",
  completed: "إكتمال",
  years: "سنوات",
  price: "السعر",
  colors: {
    white: "أبيض",
    black: "أسود",
    grey: "رمادي",
    gray: "رمادي",
    brown: "بني",
    yellow: "أصفر",
    red: "أحمر",
    blue: "أزرق",
    orange: "برتقالي",
    green: "أخضر",
    pink: "وردي",
    gold: "ذهبي",
    silver: "فضي",
  },
  materials: {
    leather: "الجلد",
    nylon: "نايلون",
    vinyl: "فينيل",
    polyester: "بوليستر",
    synthetic: "اصطناعي",
  },
  body_types: {
    sedan: "سيدان",
    hatchback: "هاتشباك",
    suv: "سوف",
    convertible: "كونفرتبل",
    "pickup truck": " البيك أب",
    van: "الفان",
    coupe: "كوبيه",
    station: "ستيشن ",
    other: "اخري",
  },
  transmissions: {
    Automatic: "أوتوماتيكي",
    Manual: "يدوي",
    "4x4": "الدفع الرباعي",
    Hyprid: "هايبريد",
    Electric: "كهربائي",
    other: "اخري",
  },
  fuel_types: {
    Petrol: "البترول",
    Gasoline: "البنزين",
    Diesel: "الديزل",
    "Natural Gas": "الغاز الطبيعي",
    Electric: "الكهربائي",
  },
  sar: "ر.س",
  header: {
    lang: "العربية",
    signup: "إنشاء حساب",
    logout: "خروج",
  },
  navBar: {
    CarsForSale: "احصل على عرض أسعار",
    buildCar: "إبني سيارتك",
    carFinance: "تمويل السيارات",
    reviews: "عرض السيارات",
  },

  intro: {
    title: "ابحث عن التالي سيارة رهيبة ...",
    title_l1: "ابحث عن التالي",
    title_l2: "سيارة رهيبة ...",
    text: "ابحث عن سيارات السيدان والهاتشباك وسيارات الدفع الرباعي المفضلة لديك من + 54 ماركة عالمية",
    text_l1: "ابحث عن سيارات السيدان والهاتشباك وسيارات",
    text_l2: "الدفع الرباعي المفضلة لديك من + 54 ماركة عالمية",
    search: {
      brand: "العلامة التجارية",
      model: "الموديل",
      type: "النوع",
      price: "السعر",
      search: "بحث",
    },
  },
  brands: {
    title: "استكشف السيارات من أفضل العلامات التجارية",
    mobileTitle: "استكشف السيارات من أفضل العلامات التجارية",
    carsAvalibale: "سيارة متاحة",
  },
  buildCar: {
    title: "إبني سيارتك",
    text: "تخصيص سيارتك عن طريق اختيار العلامة التجارية المفضلة لديك ، نموذج ، نوع ، شكل الجسم ، اللون ، تقليم مخصص ، انتقال ، سعة المحرك ونوع الوقود",
    button: "قم ببناء سيارتك",
  },
  shapes: {
    title: "إستكشف شكل سيارتك",
  },
  calculator: {
    title: "حاسبة قرض السيارة",
    brand: "العلامة التجارية",
    model: "الموديل",
    year: "سنة الانتاج",
    color: "لون الجسم",
    type: "نوع العربية",
    button: "إحسب القرض",
  },
  carCard: {
    price: "السعر",
    seller: "بائع",
  },
  sellers: {
    topSellers: "الأكثر مبيعًا",
  },
  news: {
    latestNews: "آخر الأخبار",
    relatedNews: "اخبار ذات صلة",
  },
  reviews: {
    carvinuReviews: " إستعراض Carvinu",
    review: "مراجعة",
    readMore: " إقرأ المزيد",
    readless: " إقرأ أقل",
  },
  footer: {
    text: "سلسلة إف تلتقط تاج الشاحنة الأكثر مبيعا في أمريكا للعام 46 على التوالي ، فورد تسجل مبيعات قياسية للسيارات الكهربائية ، وتسخر رقم 2 من شركة صناعة السيارات الكهربائية ، وتوسع فورد إجمالي السوق",
    privacyPolicy: "السياسات",
    termUse: "الشروط و الأحكام",
  },
  carsFilter: {
    detailedSearch: "بحث مفصل",
    carBrand: "العلامة التجاريه للسيارة",
    searchforBrand: "البحث",
    viewAllBrands: "عرض الكل",
    viewLess: "عرض أقل",
    price: "السعر",
    from: "من",
    to: "إلى",
    transmission: "النقل",
    bodyShape: "الشكل",
    fuelType: "نوع الوقود",
    engineCapacity: "سعة المحرك",
    viewResult: "عرض النتيجة",
    clear: "تنقية",
    filter: "تصفية",
  },
  carFinanceSteps: {
    carDetails: "تفاصيل السيارة",
    personalInfo: "معلومات شخصية",
    workInfo: "معلومات العمل",
    financialInfo: "معلومات مالية",
    loansInfo: "معلومات القروض",
  },

  carFinance: {
    carDetails: {
      brand: "العلامة التجارية",
      model: "الموديل",
      year: "سنة الانتاج",
      color: "لون الجسم",
      interiorMaterial: "المواد الداخلية",
      materialColor: "لون المواد",
      seller: "البائع",
    },
    personalInfo: {
      fname: "الإسم الأول",
      lname: "الإسم الأخير",
      email: "البريد الإلكتروني",
      mobileNumber: "رقم الهاتف",
      nationality: "الجنسية",
      nationalID: "الرقم القومي",
    },
    workInfo: {
      workType: "نوع العمل",
      workDuration: "مدة العمل",
      salary: "الراتب",
      payrollBank: "بنك الرواتب",
    },
    financialInfo: {
      food_expense: "نفقات الغذاء",
      wages_for_domestics: "أجور الخادمات",
      housing_rent: "السكن (الإيجار)",
      education_expense: "نفقات التعليم",
      health_care_expense: "نفقات الرعاية الصحية",
      transport_and_communication_expense: "مصاريف النقل والاتصالات",
      insurance_expense: "مصاريف التأمين",
      other_expense: "مصروفات أخرى",
    },
    loanInfo: {
      homeFinance: "التمويل العقاري",
      personalFinance: "التمويل الشخصي",
      SocialBank_others: "البنك الاجتماعي وغيرها",
      autoLease: "تأجير السيارات",
      creditCards: "بطاقات الائتمان",
    },
    result: {
      contact_alert: "سوف نتصل بك قريبا",
      banksOffers: "عروض البنوك",
      plans: "خطط",
      installment: "تقسيط",
      year: "سنة",
      downPayment: "دفعة أولى",
      loanAmount: "مبلغ القرض",
      adminFees: "الرسوم الإدارية",
      tenure: "الحيازة",
      lastBatch: "الدفعة الأخيرة",
      bookNow: "احجز الآن",
      loginToBook: "سجل الدخول و احجز الآن ",
      MonthlyInstallment: "القسط الشهري",
    },
    finicialInfoModal: {
      calculateLoan: "احسب القسط",
      savedInfo: "المعلومات المحفوظة مسبقا",
      savedInfoText: "احسب القسط باستخدام المعلومات المحفوظة مسبقا",
      newInfo: "معلومات جديدة",
      newInfoText: "استخدم حاسبة الاقساط بواسطة معلومات جديدة",
    },
  },
  buildCarPage: {
    step_title: "اختر ما تفضله",
    brand: "العلامة التجارية",
    engineCapacity: "سعة المحرك",
    carTrim: "تقليم السيارة",
    gearBox: "علبة التروس",
    paymentMethod: "طريقة الدفع",
    sectionCard: {
      title_line1: "إبني سيارتك",
      title_line2: "الخاصة بك",
    },
  },
  carPage: {
    price: {
      starting: "يبدأ من",
      text: "سعر السيارة قابل للتغيير طبقاً لمفضلاتك الشخصية ",
    },
    calculator: {
      header: "حاسبة الأقساط",
      text: "صمم خطة تقسيط خاصة بك",
    },
    specifications: {
      specifications: "المواصفات",
      body_type: "نوع الجسم",
      body_volume: "حجم الجسم",
      tank: "دبابة",
      engine: "المحرك",
      seats: "مقاعد",
      liters: "لتر",
      cylinders: " اسطوانات",
    },
    warranty: {
      title: "تغطية الضمان",
      warranty: "الضمان",
      distance: "المسافة",
      km: "كم",
      years: "سنوات",
    },
    features: {
      title: "الميزات",
      fuel_type: "نوع الوقود",
      trim: "قطع",
      internal_toolkit: "مجموعة الأدوات الداخلية",
      engine: "المحرك",
    },
    availability: {
      title: "تجد سيارتك المفضلة في",
      car_customization: "تخصيص السيارات",
      body_color: "لون السيارة",
      interior_material: "الخامات الداخلية",
      interior_color: "لون الخامات الداخلية",
      footer_text: "يمكنك طلب عرض أسعار لحجز هذه السيارة أو البدء في حساب قرضك",
      calculateLoan: "حاسبة الأقساط",
      requestQuotation: "طلب عرض سعر",
    },
    quotationSuccess: {
      title: "تم ارسال الطلب بنجاح",
    },
    fullname: "الاسم الكامل",
    mobile: "رقم الهاتف",
    createPassword: "أنشئ كلمة مرور",
    password: "كلمة المرور",
    LoginandRequest: "تسجيل الدخول و طلب عرض سعر",
    registerModal: {
      title: "يجب أن يكون لديك حساب",
      text: "الرجاء إدخال المعلومات أدناه حتى نتمكن من الرد عليك بعرض الأسعار المطلوب",
    },
    loginModal: {
      title: "يجب عليك تسجيل الدخول",
      text: "الرجاء إدخال المعلومات أدناه حتى نتمكن من الرد عليك بعرض الأسعار المطلوب",
    },
    quotationModal: {
      title: "تم استلام الطلب بنجاح",
      text: "أحد أعضاء فريقنا سيتواصل معك قريبًا",
      Okay: "حسناً",
      useLoanCalc: " استخدم حاسبة الأقساط ",
    },
  },
  carReviewsPage: {
    title: "تقييمات السيارات",
    latestReviews: "أحدث التقييمات",
    allReviews: "كل التقييمات",
  },
  authPages: {
    haveAcount: "هل لديك حساب؟",
    dontHaveAccount: "ليس لديك حساب؟",
    sign_up: "إنشاء حساب",
    sign_in: "تسجيل الدخول",
    login: "تسجيل الدخول",
    forget_password: "هل نسيت كلمة السر؟",
    email: "البريد الإلكتروني",
    fname: "الإسم الأول",
    lname: "الإسم الأخير",
    mobile: "رقم الهاتف",
    password: "كلمة المرور",
    confirm_password: "تأكيد كلمة المرور",
    new_password: "كلمة المرور الجديدة",
    confirm_new_password: "تأكيد كلمة المرور الجديدة",
    email_address: "البريد الإلكتروني",
    or: "أو",
    skip: "تخطي",
    card: {
      text_line1: "طريقة جديدة",
      text_line2: "لبيع وشراء السيارات",
    },
    sign_up_page: {
      header: "أخبرنا عن نفسك",
      text: "أدخل التفاصيل الخاصة بك لإنشاء حسابك الخاص",
      agreeTerms: "أوافق على الشروط والأحكام",
      continue: "استمر",
      success_title: "شكرًا لك!",
      success_txt_p1: " لقد أرسلنا بريدًا إلكترونيًا إلى",
      success_txt_p2: "انقر علي  رابط  التأكيد في البريد الإلكتروني للتحقق من حسابك",
    },
    login_page: {
      header: "تسجيل الدخول إلى حسابك",
      text: "أدخل التفاصيل الخاصة بك للمتابعة",
      signup_google: "التسجيل باستخدام حساب جوجل",
      signup_fb: " التسجيل باستخدام حساب فيسبوك ",
      signup_tw: "التسجيل باستخدام حساب تويتر",
    },
    forget_password_page: {
      recovery: {
        title_line1: "هل نسيت كلمة السر؟",
        title_line2: " أدخل التفاصيل الخاصة بك لاستعادة الحساب",
        text: "أدخل بياناتك للمتابعة",
        recover: "استعادة",
      },
      verify: {
        title_l1: "تحقق من حسابك",
        title_l2: "أدخل الأربعة أرقام المرسلة إلى هاتفك",
        text: "أدخل بياناتك للمتابعة",
        verify: "تحقق",
      },
      reset_password: {
        title: "اعد ضبط كلمة السر",
        text: "أدخل كلمة المرور الجديدة الخاصة بك وتأكيدها",
        setPassword: "ضبط كلمة السر",
      },
      success_reset_password: {
        title: "تم تغيير كلمة المرور بنجاح",
        text: "لقد تم تحديث كلمة المرور الخاصة بك بنجاح",
      },
    },
  },
  account: {
    accInfo: "معلومات الحساب",
    accInfoDesc: "الملف الشخصي ، الصورة والاسم",
    loginDetails: "تفاصيل الدخول",
    loginDetailsDesc: "كلمة المرور والأمان",
    expenses: "المصاريف",
    expensesDesc: "معلومات التعاقدات",
    fullName: "الاسم  الكامل",
    fullNameAr: "الاسم باللغة العربية",
    gender: "النوع",
    birthDate: "تاريخ الميلاد",
    enterBirthDate: "ادخل تاريخ الميلاد",
    email: "البريد الإلكتروني",
    enterEmail: "ادخل البريد الإلكتروني",
    address: "العنوان",
    enterAddress: "ادخل العنوان",
    nationalId: "الرقم القومي",
    enterNationalId: "ادخل الرقم القومي",
    generalInfo: "معلومات عامة",
    generalInfoDesc: "كل شخص حضر اجتماعاً من قبل يمكنه أن يتذكر عادة تبادل بطاقات العمل المألوفة للغاية.",
    deactivateAccount: "طلب وقف الحساب",
    updateAccount: "تحديث الاعدادات",
    workInfo: "معلومات العمل",
    workType: "نوع العمل",
    WorkDuration: "مدة العمل",
    enterYourWorkDuration: "ادخل مدة العمل",
    salary: "الراتب",
    payrollBank: "بنك الرواتب",
    financialInfo: "معلومات مالية",
    foodExpense: "نفقات الطعام",
    enterFoodExpense: "ادخل نفقات الطعام",
    wagesForDomestics: "أجور الخادمات",
    enterWagesForDomestics: "ادخل أجور الخادمات",
    housingRent: "السكن (الإيجار)",
    enterHousingRent: "ادخل السكن (الإيجار)",
    educationExpense: "نفقات التعليم",
    enterEducationExpense: "ادخل نفقات التعليم",
    healthCareExpense: "نفقات الرعاية الصحية",
    enterHealthCareExpense: "ادخل نفقات الرعاية الصحية",
    transportAndCommunicationExpense: "مصاريف النقل والاتصالات",
    enterTransportAndCommunicationExpense: "ادخل مصاريف النقل والاتصالات",
    insuranceExpense: "مصاريف التأمين",
    enterInsuranceExpense: "ادخل مصاريف التأمين",
    otherExpense: "مصروفات أخرى",
    enterOtherExpense: "ادخل مصروفات أخرى",
    homeFinance: "التمويل المنزلي",
    enterHomeFinance: "ادخل التمويل المنزلي",
    personalFinance: "التمويل الشخصي",
    enterPersonalFinance: "ادخل التمويل الشخصي",
    socialBankOthers: "البنك الاجتماعي وغيرها",
    enterSocialBankOthers: "ادخل البنك الاجتماعي وغيرها",
    autoLease: "تأجير السيارات",
    enterAutoLease: "ادخل تأجير السيارات",
    creditCards: "بطاقات الائتمان",
    enterCreditCards: "ادخل بطاقات الائتمان",
    loansInfo: "معلومات القروض",
    PasswordAndQuestions: "كلمة المرور والأسئلة",
    changePassword: "تغيير كلمة المرور",
    currentPassword: "كلمة المرور الحالية",
    enterCurrentPassword: "ادخل كلمة المرور الحالية",
    newPassword: "كلمة المرور الجديدة",
    enterNewPassword: "ادخل كلمة المرور الجديدة",
    confirmPassword: "تأكيد كلمة المرور",
    enterConfirmPassword: "ادخل تأكيد كلمة المرور",
    sendCode: "إرسال الرمز",
    mobileNumber: "رقم الهاتف",
    enterMobileNumber: "ادخل رقم الهاتف",
    invalidMobileNumber: "رقم الهاتف غير صحيح",
    enter4Digits: "أدخل الارقام الأربعة المرسلة الى",
    enterToProceed: "ادخل الكود  للمتابعة",
    contactSupport: "تواصل مع الدعم",
    facingIssues: " إذا كنت تواجه أي مشكلة",
    myAccount: "حسابي",
    accountUpdated: "تم تحديث الحساب بنجاح",
    selectBank: "اختر البنك",
    selectWorkType: "اختر نوع العمل",
    employmentStatus: "حالة العمل",
    selectEmploymentStatus: "اختر حالة العمل",
    government: "حكومي",
    private: "خاص",
    retired: "متقاعد",
    stillWorking: "مازلت اعمل",
    passwordLength: "يجب أن تحتوي كلمة المرور على 6 أحرف على الأقل",
    passwordMatch: "كلمة المرور غير متطابقة",
    passwordUpdated: "تم تحديث كلمة المرور بنجاح",
    employee: "موظف",
  },
  cancel: "إلغاء",
  edit: "تعديل",
  thisFieldIsRequired: "هذا الحقل مطلوب",
  confirm: "تأكيد",
  select: "-اختر-",
  yes: "نعم",
  no: "لا",
  banks_offers: "عروض البنوك",
  no_offers: "لم يتم العثور على عروض",
  no_offers_description: "يرجى المحاولة بالبحث عن مركبات أخرى أو إدخال معلومات حساب صحيحة.",
  sellerPopup: {
    title: "نحن دائما هنا لمساعدتك",
    workingHours: "ساعات العمل من 9 صباحًا إلى 5 مساءً",
  },
};
